<!-- 系统参数设置 -->
<template>
  <div class="system-params">
    <h3>解锁资格相关设置</h3>
    <div class="setup-block">
      <div class="row">
        <label>招标资格解锁：</label>
        <el-radio-group v-model="info.caseReleaseSwitch">
          <el-radio :label="1">开</el-radio>
          <el-radio :label="0">关</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label>招标资格解锁原价：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.caseReleaseLitigationOriginalAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.caseReleaseNonLitigationOriginalAmount"></el-input>元
        </div>
      </div>
      <div class="row">
        <label>招标资格解锁售价：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.caseReleaseLitigationCurrentAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.caseReleaseNonLitigationCurrentAmount"></el-input>元
        </div>
      </div>
      <div class="row">
        <label>求助资格解锁：</label>
        <el-radio-group v-model="info.helpReleaseSwitch">
          <el-radio :label="1">开</el-radio>
          <el-radio :label="0">关</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label>求助资格解锁原价：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.helpReleaseLitigationOriginalAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.helpReleaseNonLitigationOriginalAmount"></el-input>元
        </div>
      </div>
      <div class="row">
        <label>求助资格解锁售价：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.helpReleaseLitigationCurrentAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.helpReleaseNonLitigationCurrentAmount"></el-input>元
        </div>
      </div>
    </div>
    <h3>招标保证金相关设置</h3>
    <div class="setup-block">
      <div class="row">
        <label>招标保证金：</label>
        <el-radio-group v-model="info.caseDepositSwitch">
          <el-radio :label="1">开</el-radio>
          <el-radio :label="0">关</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label>招标保证金金额：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.caseDepositLitigationAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.caseDepositNonLitigationAmount"></el-input>元
        </div>
      </div>
      <div class="row">
        <label>求助保证金：</label>
        <el-radio-group v-model="info.helpDepositSwitch">
          <el-radio :label="1">开</el-radio>
          <el-radio :label="0">关</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label>求助保证金金额：</label>
        <div class="price">
          诉讼业务<el-input v-model="info.helpDepositLitigationAmount"></el-input>元
        </div>
        <div class="price">
          非诉讼业务<el-input v-model="info.helpDepositNonLitigationAmount"></el-input>元
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button type="primary" @click="submit">提交修改</el-button>
      <el-button type="text" @click="operate">操作日志</el-button>
    </div>
    <!-- 操作日志 dialog -->
    <el-dialog title="操作日志" :visible.sync="dialogVisible" width="60%">
      <el-table :data="tableData" border>
        <el-table-column property="accountName" label="操作人员" width="100">
        </el-table-column>
        <el-table-column property="requestName" label="动态行为">
        </el-table-column>
        <el-table-column property="startTime" label="动态时间">
        </el-table-column>
        <el-table-column property="parameter" label="请求参数" width="260">
        </el-table-column>
        <el-table-column property="result" label="请求结果">
          <template slot-scope="scope">
            <span v-if="scope.row.result && JSON.parse(scope.row.result)">{{JSON.parse(scope.row.result).desc}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right;">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {sysConfig,updateSysConfig,operateLogAdmin} from '../../api/system'
export default {
  data () {
    return {
      info:{},
      info2:{},
      dialogVisible:false,
      tableData:[],
      form:{
        page:1,
        perPage:5,
        uri:'/confined/case/updateSysConfig',
      },
      total:0
    };
  },
  components: {},
  computed: {},
  methods: {
    getInfo(){
      sysConfig().then(res=>{
        let data = res.data;
        if(data.code ==='000'){
          this.info = data.content;
          this.info2 = Object.assign({},data.content);
        }else{
          console.log(data.desc);
        }
      })
    },
    submit(){
      let para = {};
      for(let key in this.info){
        if(this.info[key] !== this.info2[key]){
          para[key] = this.info[key];
        }
      }
      updateSysConfig(para).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getInfo();
        }else{
          this.$message.warning(data.desc)
        }
      })
    },
    currentChange(val){
      this.form.page = val;
      this.getOperateList();
    },
    getOperateList(){
      operateLogAdmin(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{

        }
      })
    },
    operate(){
      this.getOperateList();
      this.dialogVisible = true;
    }
  },
  created(){
    this.getInfo();
  }
}

</script>
<style lang='scss' scoped>
.system-params{
  padding:0 20px;
  h3{
    margin: 30px 0 20px;
    font-weight: 400;
    color: #1f2f3d;
    font-size: 20px;
  }
  .setup-block{
      border: 1px solid #ebebeb;
      border-radius: 3px;
      padding:20px;
      width: 920px;
      .row{
        display: flex;
        align-items: center;
        margin:10px 0;
        label{
          width: 160px;
          text-align: right;
          padding-right: 16px;
        }
        .el-radio-group{
          width:150px;
          display: flex;
        }
        .price{
          background-color: #fafafa;
          margin-right: 20px;
          padding:10px;
          .el-input{
            width: 150px;
            padding:0 10px;
          }
        }
      }
  }
  .btns{
    margin:15px 0;
  }
  .setup-block:hover{
    box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  }
}
</style>